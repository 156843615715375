import React from "react";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Link } from "react-router-dom";
import PdfCGV30j from "../../documents/CGV30j.pdf";

const StyleDivContainer = {
  marginTop: "40px",
};

const GreenCheckbox = withStyles({
  root: {
    color: "#0FA600",
    "&$checked": {
      color: "#0FA600",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function TexteCGV() {
  return (
    <div>
      J'accepte les{" "}
      <Link to={PdfCGV30j} target="_blank" className="BoutonLien">
        conditions générales d'utilisations
      </Link>
      &nbsp; du logiciel.
    </div>
  );
}

export default function InputCGV({
  checked,
  texte,
  version,
  fichier,
  onChange,
}) {
  const HandleChange = (event) => {
    let retour = {
      checked: event.target.checked,
      version: "CGV30j.pdf",
    };
    if (onChange) {
      onChange(retour);
    }
  };

  return (
    <div style={StyleDivContainer}>
      <FormControlLabel
        control={
          <GreenCheckbox
            checked={checked}
            onChange={HandleChange}
            name="checkedG"
          />
        }
        label={<TexteCGV />}
      />
    </div>
  );
}
