import React, { useEffect, useState } from "react";
import Style from "./VerificationCompte.module.css";
import BoutonActionPrincipale from "../../../components/Boutons/BoutonActionPrincipale";
import { GET_ApiUtilistateurs_verification } from "../../../Data/ApiUtilisateur";
import MessageErreur from "../../../components/Messages/MessageErreur";
import goToApp from "../../../functions/redirect";

export default function VerificationCompte({ hash, callbackActivation }) {
  //NES UseSState
  const [code, setCode] = useState("");
  const [erreur, setErreur] = useState();
  const [isActive, setIsActive] = useState(false);

  //NES astuce pour mettre le focus dans le 1er champ au montage du composant
  useEffect(() => {
    document.getElementById("1").focus();
  }, []);

  //NES fonction pour ne garder que les chiffres et placer le focus dans le champ suivant
  const VerifyNumber = (event) => {
    let caractere = event.target.value;
    //NES chiffres only
    caractere = caractere.replace(/[^0-9]/gi, "");
    //NES pour effacer les vilains caractères
    event.target.value = caractere;
    //NES au fait c'est quoi le code saisi?
    let lecodesaisi =
      document.getElementById("1").value +
      document.getElementById("2").value +
      document.getElementById("3").value +
      document.getElementById("4").value;
    setCode(lecodesaisi);
    //NES je place le focus dans le champ suivant
    let nextId = parseInt(event.target.id, 0) + 1;
    if (nextId <= 4 && caractere.length === 1) {
      document.getElementById(nextId).focus();
    }
  };

  //NES callbak pour traiter le retour de l'API
  const RetourApi = (reponseApiJson) => {
    //NES Gestion de l'erreur
    if (reponseApiJson.code === "erreur") {
      setErreur(reponseApiJson.erreur.message);
    }
    //NES c'est bon c'est activé
    if (reponseApiJson.code === "OK") {
      //NES Si j'ai un callback
      if (callbackActivation) {
        callbackActivation(true);
      } else {
        //NES si je gère l'affichage dans mon composant
        setErreur();
        setIsActive(true);
      }
    }
  };

  //NES Appel de l'API pour la vérification

  const ValiderCode = () => {
    GET_ApiUtilistateurs_verification({ hash: hash, code: code }, RetourApi);
  };

  return (
    <div className={Style.VerificationCompte}>
      {isActive === false ? (
        <div className={Style.VerificationCompte}>
          <div className="Titre2">Code d'activation</div>
          <div className="SousTitre1">
            Vous avez reçu sur votre adresse email, un code d'activation.
          </div>
          {erreur ? <MessageErreur message={erreur} /> : ""}
          <div className={Style.CodeEtBouton}>
            <div className={Style.Code}>
              <input
                type="text"
                className={Style.InputCode}
                maxLength="1"
                onChange={VerifyNumber}
                id="1"
              />
              <input
                type="text"
                className={Style.InputCode}
                maxLength="1"
                id="2"
                onChange={VerifyNumber}
              />
              <input
                type="text"
                className={Style.InputCode}
                maxLength="1"
                id="3"
                onChange={VerifyNumber}
              />
              <input
                type="text"
                className={Style.InputCode}
                maxLength="1"
                id="4"
                onChange={VerifyNumber}
              />
            </div>
            <div className={Style.BoutonValider}>
              <BoutonActionPrincipale
                texte="Valider le code"
                fullWidth
                onClick={ValiderCode}
                disabled={code.length === 4 ? false : true}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={Style.VerificationOK}>
          <div className="Titre2">Merci votre compte est activé !</div>
          <div className="SousTitre1">
            Merci , il s'agit d'un élément important concernant l'accès sécurisé
            à vos données
          </div>
          <div className={Style.BoutonValider}>
            <BoutonActionPrincipale
              texte="Accéder à Be"
              fullWidth
              onClick={goToApp}
            />
          </div>
        </div>
      )}
    </div>
  );
}
