import React, { useState } from "react";

import InputTexte from "./InputTexte";

export default function InputEmail({ value, label, onChange }) {
  const [isErreur, setIsErreur] = useState(false);
  const messageErreur = `L'adresse email n'est pas correcte`;

  function checkEmailValide(event) {
    let email = event.target.value;
    const mailformat = /^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/g;

    if (email) {
      if (email.match(mailformat)) {
        console.log("OK");
        setIsErreur(false);
      } else {
        console.log("KO");
        setIsErreur(true);
      }
    } else {
      setIsErreur(false);
    }
  }

  return (
    <InputTexte
      value={value}
      label={isErreur ? messageErreur : label}
      isErreur={isErreur}
      onBlur={checkEmailValide}
      onChange={onChange}
    />
  );
}
