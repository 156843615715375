import React, { useState } from "react";
import Style from "./PageInscription.module.css";
import PhotoGauche from "./Components/PhotoGauche";
import EnteteInscription from "./Components/EnteteInscription";
import AProposDeVous from "./Components/AProposDeVous";
import BoutonActionPrincipale from "../../components/Boutons/BoutonActionPrincipale";
import Description, { DescriptionFacturation } from "./Components/Description";
import Facturation from "./Components/Facturation";
import VerificationCompte from "./Components/VerificationCompte";
import Stepper from "../../components/Navigation/Stepper";
import { POST_ApiUtilisateurs_inscription } from "../../Data/ApiUtilisateur";
import MessageErreur from "../../components/Messages/MessageErreur";
import PhotoNicoJez from "../../Images/PhotoJeremyNicolas.png";

export default function PageInscription() {
  //NES Gestion du State des etapes
  const [etape, setEtape] = useState(1);
  //NES Gestion des erreurs
  const [erreur, setErreur] = useState();
  //NES Gestion de l'activation
  const [hashActivation, setHashActivation] = useState();

  //NES Gestion du State de mon formulaire
  const [values, setValues] = useState({
    nom: "",
    prenom: "",
    email: "",
    password: "",
    cgv: { checked: false, version: "" },
    facturation_mode: "abonnement_mensuel",
    numero_carte: "",
    nom_carte: "",
    date_carte: "",
    cryptogramme_carte: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //NES cas particulier des CGV
  const handleCgv = (cgv) => {
    setValues({ ...values, cgv: cgv });
  };

  //NES gestion des étapes d'affichage
  const etapeSuivante = () => {
    setEtape(etape + 1);
  };

  //NES Inscription
  const inscription = () => {
    POST_ApiUtilisateurs_inscription(values, callbackApi);
  };

  //NES callback de l'API
  const callbackApi = (data) => {
    //NES cas d'erreur génériques
    if (data.code === "erreur") {
      setErreur(data.erreur.message);
      //NES Gestion du cas d'erreur du mail existant
      if (data.erreur.code === "email_deja_existant") {
        setErreur("Cette adresse email est déjà utilisée");
      }
      //NES je repasse à l'étape 1
      setEtape(1);
    }

    //NES Pas de cas d'erreur -> redirection
    if (data.code === "OK") {
      setHashActivation(data.verification_compte.hash);
      setErreur();
      etapeSuivante();
    }
  };

  //NES suite à l'activation on passe à la page inscription terminée
  const goPageActivation = () => {
    console.log("goPageActivation");
    window.location.href = "inscription/termine";
  };

  //!NIE DEBUG
  /*
  useEffect(() => {
    console.log(values);
  }, [values]);*/

  return (
    <div className={Style.PageInscription}>
      <PhotoGauche Photo={PhotoNicoJez} />
      <div className={Style.PartieDroite}>
        <EnteteInscription />
        <div className={Style.Affichage}>
          <div className={Style.Titres}>
            <div className="Titre1">Inscription</div>
            <div className="SousTitre1">
              C'est rapide, ça va prendre moins de 5min
            </div>
          </div>
          <div className={Style.Stepper}>
            <Stepper etape={etape} />
          </div>
          {/***********PARTIE 1******************** */}

          {etape === 1 ? (
            <div className={Style.Etape}>
              <div>
                <AProposDeVous
                  values={values}
                  onChange={handleChange}
                  onCgvChange={handleCgv}
                />
                <div className={Style.BoutonActionPrincipale}>
                  <BoutonActionPrincipale
                    texte="Passer à l'étape suivante"
                    onClick={etapeSuivante}
                    fullWidth
                    disabled={
                      values.nom === "" ||
                      values.prenom === "" ||
                      values.login === "" ||
                      values.password === "" ||
                      !values.cgv.checked === true
                    }
                  />
                </div>
                {erreur ? <MessageErreur message={erreur} /> : ""}
              </div>
              <div className={Style.Description}>
                <Description />
              </div>
            </div>
          ) : (
            ""
          )}
          {/***********PARTIE 2******************** */}
          {etape === 2 ? (
            <div className={Style.Etape}>
              <div>
                <Facturation onChange={handleChange} />
                <div className={Style.BoutonActionPrincipale}>
                  <BoutonActionPrincipale
                    onClick={inscription}
                    texte="Activer mon compte"
                    fullWidth
                    disabled={
                      values.numero_carte === "" ||
                      values.nom_carte === "" ||
                      values.date_carte === "" ||
                      values.cryptogramme_carte === ""
                    }
                  />
                </div>
              </div>
              <div className={Style.Description}>
                <DescriptionFacturation />
              </div>
            </div>
          ) : (
            ""
          )}
          {/***********PARTIE 3******************** */}
          {etape === 3 ? (
            <div className={Style.Etape}>
              <div className={Style.VerificationCompte}>
                <VerificationCompte
                  hash={hashActivation}
                  callbackActivation={goPageActivation}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
