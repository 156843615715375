import React, { useState } from "react";
import InputTexte from "./InputTexte";

export default function InputNombres({ label, nbChiffresError, onChange }) {
  const [isErreur, setIsErreur] = useState(false);
  const messageErreur = `Date invalide`;

  //NES controle de la value

  const remplaceChiffres = (event) => {
    let tempvalue = event.target.value;
    let mois = "";
    let annee = "";
    //NES uniquement des chiffres
    tempvalue = onlyChiffres(tempvalue);
    //NES je formatte la saisie
    if (tempvalue.length > 2) {
      mois = tempvalue.slice(0, 2);
      annee = tempvalue.slice(2);
      tempvalue = mois + "/" + annee;
    }
    //NES mise à jour du champ
    event.target.value = tempvalue;
    //NES on teste la date par rapport à aurjourd'hui
    let dateNow = Date.now();
    let dateCarte = new Date("20" + annee + "-" + mois + "-30");
    if (dateCarte <= dateNow && tempvalue.length === 5) {
      setIsErreur(true);
    } else {
      setIsErreur(false);
    }

    //NES on retourne l'event au composant parent
    if (onChange) {
      onChange(event);
    }
  };

  function onlyChiffres(tempValue) {
    //NES je remplace les chiffres du haut pour les portables APPLE
    tempValue = tempValue.replace(/&/, "1");
    tempValue = tempValue.replace(/é/, "2");
    tempValue = tempValue.replace(/"/, "3");
    tempValue = tempValue.replace(/'/, "4");
    tempValue = tempValue.replace(/\(/, "5");
    tempValue = tempValue.replace(/§/, "6");
    tempValue = tempValue.replace(/è/, "7");
    tempValue = tempValue.replace(/!/, "8");
    tempValue = tempValue.replace(/ç/, "9");
    tempValue = tempValue.replace(/à/, "0");

    //NES chiffres only
    tempValue = tempValue.replace(/[^0-9]/gi, "");

    return tempValue;
  }

  return (
    <InputTexte
      label={isErreur ? messageErreur : label}
      onChange={remplaceChiffres}
      isErreur={isErreur}
    />
  );
}
