import React, { useState } from "react";
import InputTexte from "./InputTexte";

export default function InputNombres({
  value,
  label,
  nbChiffresError,
  onChange,
}) {
  const [isErreur, setIsErreur] = useState(false);
  const messageErreur = `Le ${label} est limité à ${nbChiffresError} chiffres`;

  //NES controle de la value

  const remplaceChiffres = (event) => {
    let tempvalue = event.target.value;
    tempvalue = onlyChiffres(tempvalue);
    event.target.value = tempvalue;
    //NES on teste le nombre de caractère max
    if (nbChiffresError && tempvalue.length > nbChiffresError) {
      setIsErreur(true);
    } else {
      setIsErreur(false);
    }
    //NES on retourne l'event au composant parent
    if (onChange) {
      onChange(event);
    }
  };

  function onlyChiffres(tempValue) {
    //NES je remplace les chiffres du haut pour les portables APPLE
    tempValue = tempValue.replace(/&/, "1");
    tempValue = tempValue.replace(/é/, "2");
    tempValue = tempValue.replace(/"/, "3");
    tempValue = tempValue.replace(/'/, "4");
    tempValue = tempValue.replace(/\(/, "5");
    tempValue = tempValue.replace(/§/, "6");
    tempValue = tempValue.replace(/è/, "7");
    tempValue = tempValue.replace(/!/, "8");
    tempValue = tempValue.replace(/ç/, "9");
    tempValue = tempValue.replace(/à/, "0");

    //NES chiffres only
    tempValue = tempValue.replace(/[^0-9]/gi, "");

    return tempValue;
  }

  return (
    <InputTexte
      value={value}
      label={isErreur ? messageErreur : label}
      onChange={remplaceChiffres}
      isErreur={isErreur}
    />
  );
}
