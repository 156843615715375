import React from "react";
import Entete from "../../components/Entete";
import Style from "./PageChoixFormule.module.css";
import BoutonActionPrincipale from "../../components/Boutons/BoutonActionPrincipale";
import IllustrationEssai from "../../Images/IllustrationEssai.png";
import IllustrationInscription from "../../Images/IllustrationInscription.png";
import { Link } from "react-router-dom";

export default function PageChoixFormule() {
  return (
    <div>
      <Entete />
      <div className={Style.PageChoixFormule}>
        <div className={Style.TitreTexte}>
          <div className="Titre1">Prêt à démarrer?</div>
          <p className="Texte">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>

        <div className={Style.ChoixFormule}>
          <div className={Style.Carte30j}>
            <img
              className={Style.IllustrationEssai}
              src={IllustrationEssai}
              alt="IllustrationEssai"
            />
            <div className={Style.TitreDescription}>
              <div className="Titre2">Essayer pendant 30j</div>
              <p>
                Vous pouvez explorer et utiliser Be pour découvrir comment il
                permet de faire ce trux super bien.
              </p>
            </div>
            <Link to="/Inscription/30jEssai">
              <BoutonActionPrincipale
                texte="Inscription pour 30j d'essai"
                fullWidth
              />
            </Link>
            <div className={Style.InformationsComplementaire}>
              <p className="InformationsObligatoires">
                A l'issue de la période d'essai, vous pouvez vous inscrire pour
                continuer à utiliser Be et conserver vos données.
              </p>
            </div>
          </div>
          <div className={Style.CarteInscription}>
            <img
              className={Style.IllustrationInscription}
              src={IllustrationInscription}
              alt="IllustrationInscription"
            />
            <div className={Style.Tarif}>
              <div className="Titre1">99€ </div>
              <div className="InformationsObligatoires">/mois</div>
            </div>
            <div className={Style.TitreDescription}>
              <div className="Titre2">Utiliser Be</div>
              <p>
                On fait le nécessaire pour que vous puissiez utiliser Be dès
                maintenant. Pour que vous puissiez faire des truc trop bien trop
                facilement
              </p>
            </div>
            <Link to="/Inscription">
              <BoutonActionPrincipale texte="Inscription " fullWidth />
            </Link>
            <div className={Style.InformationsComplementaire}>
              <p className="InformationsObligatoires">
                Une fois votre incsription terminée, on prendra contact avec
                vous pour votre récupération des données de votre logiciel
                actuel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
