import React from "react";
import Style from "./Stepper.module.css";

const Etapes = [
  {
    numero: 1,
    label: "A propos de vous",
  },
  {
    numero: 2,
    label: "Facturation",
  },
  {
    numero: 3,
    label: "Activation du compte",
  },
];

function Step({ numero, label, actif }) {
  if (actif >= numero) {
    return (
      <div className={Style.Etape}>
        <div className={Style.NumeroEtape}>{numero}</div>
        <div className={Style.Label}>{label}</div>
      </div>
    );
  } else {
    return (
      <div className={Style.Etape}>
        <div className={Style.NumeroEtapeInactif}>{numero}</div>
        <div className={Style.LabelInactif}>{label}</div>
      </div>
    );
  }
}

export default function Stepper({ etape }) {
  return (
    <div className={Style.Stepper}>
      <hr className={Style.Trait} />
      <div className={Style.Etapes}>
        {Etapes.map((item) => (
          <Step
            numero={item.numero}
            label={item.label}
            actif={etape}
            key={item.numero}
          />
        ))}
      </div>
    </div>
  );
}
