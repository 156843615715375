import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";

import PageHome from "./Pages/PageHome";

//NES Inscription
import PageChoixFormule from "./Pages/Inscription/PageChoixFormule";
import Page30jEssai from "./Pages/Inscription/Page30jEssai";
import PageInscriptionTermine from "./Pages/Inscription/PageInscriptionTermine";
import PageVerificationCompte from "./Pages/Inscription/PageVerificationCompte";
import PageInscription from "./Pages/Inscription/PageInscription";

function App() {
  return (
    <Router>
      <div className="App">
        <Route path="/" exact component={PageHome} />
        <Route path="/ChoixFormule" exact component={PageChoixFormule} />
        <Route path="/Inscription/30jEssai" exact component={Page30jEssai} />
        <Route path="/Inscription/Termine" component={PageInscriptionTermine} />
        <Route path="/Inscription" exact component={PageInscription} />
        <Route
          path="/Inscription/Verification"
          component={PageVerificationCompte}
        />
      </div>
    </Router>
  );
}

export default App;
