import React from "react";
import Style from "./Fireworks.module.css";

const Data = [
  {
    taille: "100px",
    top: "70px",
    left: "4%",
    duree: "1s",
    couleur: "#208AB020",
  },
  {
    taille: "200px",
    top: "-20px",
    left: "40%",
    duree: "1.5s",
    couleur: "#208AB040",
  },
  {
    taille: "40px",
    top: "130px",
    left: "60%",
    duree: "2s",
    couleur: "#024C6520",
  },
  {
    taille: "120px",
    top: "90px",
    left: "80%",
    duree: "3.2s",
    couleur: "#024C6520",
  },
  {
    taille: "250px",
    top: "-90px",
    left: "50%",
    duree: "1.9s",
    couleur: "#024C6510",
  },
  {
    taille: "130px",
    top: "-20px",
    left: "11%",
    duree: "4.5s",
    couleur: "#0FA60030",
  },
  {
    taille: "50px",
    top: "150px",
    left: "48%",
    duree: "3.7s",
    couleur: "#0FA60020",
  },
  {
    taille: "150px",
    top: "20px",
    left: "91%",
    duree: "2.7s",
    couleur: "#0FA60020",
  },
  {
    taille: "300px",
    top: "-150px",
    left: "18%",
    duree: "3.7s",
    couleur: "#0FA60010",
  },
  {
    taille: "30px",
    top: "22px",
    left: "3%",
    duree: "1.1s",
    couleur: "#0FA60070",
  },
  {
    taille: "50px",
    top: "82px",
    left: "1%",
    duree: "1.1s",
    couleur: "#208AB020",
  },
  {
    taille: "70px",
    top: "22px",
    left: "-5%",
    duree: "1.5s",
    couleur: "#208AB060",
  },
];

function Rond({ taille, top, left, duree, couleur }) {
  const monStyle = {
    width: taille,
    height: taille,
    top: top,
    left: left,
    backgroundColor: couleur,
    animationDuration: duree,
  };

  return <div className={Style.RondBleu} style={monStyle}></div>;
}

export default function FireWorks() {
  return (
    <div className={Style.Fireworks}>
      {Data.map((rond, id) => (
        <Rond
          taille={rond.taille}
          top={rond.top}
          left={rond.left}
          duree={rond.duree}
          couleur={rond.couleur}
          key={id}
        />
      ))}
    </div>
  );
}
