import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const ColorButtonWhite = withStyles((theme) => ({
  root: {
    color: "#2182A6",
    backgroundColor: "#EFEFEF",
    fontWeight: "700",
    textTransform: "none",
    fontFamily: "Lato",

    "&:hover": {
      backgroundColor: "white",
    },
  },
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    fontFamily: "Lato",
    fontWeight: "700",
    textTransform: "none",
    color: "#FFF",
    backgroundColor: "#0FA600",
    borderRadius: "4px",
    paddingLeft: "20px",
    paddingRight: "20px",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.14)",
    height: "50px",
    "&:hover": {
      backgroundColor: "#0D9500",
      boxShadow: "1px 2px 5px 0 rgba(0,0,0,0.3)",
    },
  },
}))(Button);

export default function BoutonActionInscription({
  texte,
  couleur,
  fullWidth,
  onClick,
  disabled,
}) {
  if (couleur === "Blanc") {
    return (
      <ColorButtonWhite
        disabled={disabled ? disabled : false}
        variant="contained"
        size="large"
        fullWidth={fullWidth}
        onClick={onClick}
      >
        {texte}
      </ColorButtonWhite>
    );
  } else
    return (
      <ColorButton
        disabled={disabled ? disabled : false}
        variant="contained"
        size="large"
        fullWidth={fullWidth}
        onClick={onClick}
      >
        {texte}
      </ColorButton>
    );
}
