import React from "react";
import Style from "./PageHome.module.css";
import { Link } from "react-router-dom";
import Entete from "../components/Entete";
import Illustion1 from "../Images/cequonfaitbien.png";
import Illustion2 from "../Images/illustration2.png";
import Photo1 from "../Images/PhotoArticle1.png";
import Photo2 from "../Images/PhotoArticle2.png";
import Photo3 from "../Images/PhotoArticle3.png";
import Photo4 from "../Images/PhotoArticle4.png";
import Photo5 from "../Images/PhotoArticle5.png";
import Photo6 from "../Images/PhotoArticle6.png";

import Illustration3 from "../Images/Chouette1.png";

import BoutonActionPrincipale from "../components/Boutons/BoutonActionPrincipale";
import BoutonActionSecondaire from "../components/Boutons/BoutonActionSecondaire";

export default function PageHome() {
  return (
    <div className={Style.PageHome}>
      <Entete />
      <div className={Style.HomePagePartie1}>
        <div className={Style.PartieGauche}>
          <div className={Style.TagPhrase}>
            Tag phrase trop bien, c'est important
          </div>
          <div className="Titre1">
            Ce que qu'on fait de mieux avec ce logiciel
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
          <div className={Style.GroupeBoutons}>
            <BoutonActionSecondaire texte="Voir les tarifs" />
            <Link to="/choixformule">
              <BoutonActionPrincipale texte="Inscription" />
            </Link>
            <p className="InformationsObligatoires">
              Tu as besoin de savoir lire correctement. Un navigateur et un
              ordianteur ou un téléphone
            </p>
          </div>
        </div>
        <div className={Style.IllustrationDroitePartie1}>
          <img src={Illustion1} alt="" />
        </div>
      </div>
      {/******************** PARTIE 2 ******************/}
      <div className={Style.HomePagePartie2}>
        <div className="TitreBloc">CONNECTED</div>
        <div className={Style.ImageEtTextePartie2}>
          <img src={Illustion2} alt="" className={Style.Illustration2} />
          <div className={Style.TitreEtTexteIllustration2}>
            <div className={Style.TitreIllustration2}>
              C'est bien c'est connecté
            </div>
            <div className={Style.TexteIllustration2}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation...
            </div>
          </div>
        </div>

        <div className={Style.ArticlesPartie2}>
          <div className={Style.ArticlePartie2}>
            <div className={Style.TitreArticlePartie2}>
              C'est bien c'est connecté
            </div>
            <div className={Style.TexteArticlePartie2}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation...
            </div>
            <img src={Photo1} alt="" className={Style.PhotoPartie2} />
          </div>
          <div className={Style.ArticlePartie2}>
            <div className={Style.TitreArticlePartie2}>
              C'est bien c'est connecté
            </div>
            <div className={Style.TexteArticlePartie2}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation...
            </div>
            <img src={Photo2} alt="" className={Style.PhotoPartie2} />
          </div>
          <div className={Style.ArticlePartie2}>
            <div className={Style.TitreArticlePartie2}>
              C'est bien c'est connecté
            </div>
            <div className={Style.TexteArticlePartie2}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation...
            </div>
            <img src={Photo3} alt="" className={Style.PhotoPartie2} />
          </div>
        </div>
      </div>
      {/******************** PARTIE 3 ******************/}
      <div className={Style.HomePagePartie3}>
        <div className={Style.TitreBackground}>C'est chouette</div>

        <img src={Illustration3} alt="" className={Style.Illustration3} />
        <div className={Style.TexteIllustration3}>
          <div className="TitreBloc">MAKE IT EASY</div>
          <div className={Style.TitrePartie3}>C'est chouette</div>
          <div className={Style.TextePartie3}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </div>
        </div>
        <div className={Style.ArticlesPartie3}>
          <div className={Style.ArticlePartie3}>
            <div className={Style.TitreArticlePartie3}>
              C'est bien c'est connecté
            </div>
            <div className={Style.TexteArticlePartie2}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation...
            </div>
            <img src={Photo4} alt="" className={Style.PhotoPartie3} />
          </div>
          <div className={Style.ArticlePartie3}>
            <div className={Style.TitreArticlePartie3}>
              C'est bien c'est connecté
            </div>
            <div className={Style.TexteArticlePartie2}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation...
            </div>
            <img src={Photo5} alt="" className={Style.PhotoPartie3} />
          </div>
          <div className={Style.ArticlePartie3}>
            <div className={Style.TitreArticlePartie3}>
              C'est bien c'est connecté
            </div>
            <div className={Style.TexteArticlePartie2}>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation...
            </div>
            <img src={Photo6} alt="" className={Style.PhotoPartie3} />
          </div>
        </div>
      </div>
      {/******************** PARTIE 4 ******************/}
      <div className={Style.HomePagePartie4}>
        <div className={Style.TitrePartie4}>Essayer ce truc dès maintenant</div>
        <div className={Style.TextePartie4}>
          Ca a l'air super bien je vais essayer 30j
        </div>
        <Link to="/choixformule">
          <div className={Style.BoutonActionPrincipale}>
            <BoutonActionPrincipale
              couleur="Blanc"
              texte="Essayer pendant 30j"
            />
          </div>
        </Link>
      </div>
    </div>
  );
}
