import React, { useState } from "react";
import InputTexte from "./InputTexte";

export default function InputCarteBancaire({ label, onChange }) {
  const [isErreur, setIsErreur] = useState(false);
  const messageErreur = `Le numéro fait plus de 16 chiffres`;

  //NES controle de la value
  const remplaceChiffres = (event) => {
    let tempvalue = event.target.value;
    tempvalue = onlyChiffres(tempvalue);
    //NES je formate le numéro de carte
    tempvalue = formatNumeroCarte(tempvalue);
    event.target.value = tempvalue;
    //NES on teste le nombre de caractère max
    let nbChiffres = tempvalue.replaceAll(" ", "").length;
    console.log({ nbChiffres });
    if (nbChiffres > 16) {
      setIsErreur(true);
    } else {
      setIsErreur(false);
    }
    //NES on retourne l'event au composant parent
    if (onChange) {
      onChange(event);
    }
  };

  //NES formatage du n° de carte à la saisie
  function formatNumeroCarte(numero) {
    let retour = numero;
    //NES j'enlève tous les espaces
    let nbChiffres = retour.replaceAll(" ", "").length;
    //NES j'ajoute un espace quand c'est bien
    let part1 = retour.slice(0, 4);
    let part2 = retour.slice(4, 8);
    let part3 = retour.slice(8, 12);
    let part4 = retour.slice(12, 16);
    let chiffresEnTrop = retour.slice(16);
    if (nbChiffres <= 4) {
      retour = part1;
    }

    if (nbChiffres > 4 && nbChiffres <= 8) {
      retour = part1 + "  " + part2;
    }

    if (nbChiffres > 8 && nbChiffres <= 12) {
      retour = part1 + "  " + part2 + "  " + part3;
    }

    if (nbChiffres > 12 && nbChiffres <= 16) {
      retour = part1 + "  " + part2 + "  " + part3 + "  " + part4;
    }

    if (nbChiffres > 16) {
      retour =
        part1 +
        "  " +
        part2 +
        "  " +
        part3 +
        "  " +
        part4 +
        "  " +
        chiffresEnTrop;
    }

    return retour;
  }

  function onlyChiffres(tempValue) {
    //NES je remplace les chiffres du haut pour les portables APPLE
    tempValue = tempValue.replace(/&/, "1");
    tempValue = tempValue.replace(/é/, "2");
    tempValue = tempValue.replace(/"/, "3");
    tempValue = tempValue.replace(/'/, "4");
    tempValue = tempValue.replace(/\(/, "5");
    tempValue = tempValue.replace(/§/, "6");
    tempValue = tempValue.replace(/è/, "7");
    tempValue = tempValue.replace(/!/, "8");
    tempValue = tempValue.replace(/ç/, "9");
    tempValue = tempValue.replace(/à/, "0");

    //NES chiffres only
    tempValue = tempValue.replace(/[^0-9]/gi, "");

    return tempValue;
  }

  return (
    <InputTexte
      label={isErreur ? messageErreur : label}
      onChange={remplaceChiffres}
      isErreur={isErreur}
    />
  );
}
