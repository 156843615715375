import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Error from "@material-ui/icons/Error";

//NES Style de de la div contenant le composant
const StyleDivContainer = {
  marginTop: "20px",
};

//NES Style de composant NORMAL
const CssTextField = withStyles({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
      textTransform: (props) =>
        props.texttransform ? props.texttransform : "none",
    },
    "& label": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#00000087"),
    },
    "& label.Mui-focused": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "DDD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#DDD",
      },
      "&:hover fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#DDD",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#208AB0",
      },
    },
  },
})(TextField);

export default function InputTexte({
  label,
  value,
  textTransform,
  onChange,
  onBlur,
  isErreur,
}) {
  if (isErreur) {
    //NES je retourne le composant erreur
  } else {
    //NES je retourne le composant normal
  }

  return (
    <div style={StyleDivContainer}>
      <CssTextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        variant="outlined"
        texttransform={textTransform}
        id={label}
        size="small"
        couleur={isErreur ? "erreur" : ""}
        InputProps={
          isErreur
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <Error style={{ color: "#F44336" }} />
                  </InputAdornment>
                ),
              }
            : {}
        }
      />
    </div>
  );
}
