import React from "react";
import Style from "./Entete.module.css";
import Logo from "./Logo";
import BoutonActionPrincipale from "./Boutons/BoutonActionPrincipale";
import BoutonActionSecondaire from "./Boutons/BoutonActionSecondaire";
import { Link } from "react-router-dom";
import goToApp from "../functions/redirect";

export default function Entete() {
  return (
    <div className={Style.Entete}>
      <Link to="/">
        <Logo />
      </Link>
      <div className={Style.Menu}>
        <li className={Style.ItemMenu}>Apprendre</li>
        <li className={Style.ItemMenu}>Support</li>
        <li className={Style.ItemMenu}>Qui sommes-nous?</li>
        <li className={Style.ItemMenu}>Tarif</li>
      </div>
      <div className={Style.ZoneBoutons}>
        <BoutonActionSecondaire
          texte="Connexion"
          couleur="Blanc"
          onClick={goToApp}
        />
        <Link to="/choixformule">
          <BoutonActionPrincipale texte="Inscription" couleur="Blanc" />
        </Link>
      </div>
    </div>
  );
}
