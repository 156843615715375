import React from "react";
import Style from "./Facturation.module.css";
import InputCarteBancaire from "../../../components/Formulaires/InputCarteBancaire";
import InputTexte from "../../../components/Formulaires/InputTexte";
import InputMoisAnnee from "../../../components/Formulaires/InputMoisAnnee";
import InputNombres from "../../../components/Formulaires/InputNombres";

export default function Facturation({ onChange }) {
  return (
    <div className={Style.Facturation}>
      <div className={Style.TitreSeparateur}>
        <div className="Titre2">Carte bancaire</div>
        <InputCarteBancaire
          label="N° de carte bancaire"
          onChange={onChange("numero_carte")}
        />
        <InputTexte
          label="Nom présent sur la carte"
          textTransform="uppercase"
          onChange={onChange("nom_carte")}
        />

        <div className={Style.DateCrypto}>
          <div className={Style.Date}>
            <InputMoisAnnee
              label="expire le "
              onChange={onChange("date_carte")}
            />
          </div>
          <div className={Style.Crypto}>
            <InputNombres
              label="Cryptogramme"
              onChange={onChange("cryptogramme_carte")}
            />
          </div>
        </div>
        <div className={Style.TitreSeparateur}>
          <div className="Titre2">Informations de facturation</div>
        </div>
        <InputTexte
          label="Nom de facturation"
          onChange={onChange("nom_facturation")}
        />
        <InputTexte
          label="Adresse"
          textTransform="uppercase"
          onChange={onChange("adresse_facturation")}
        />
        <InputTexte
          label="Code postal"
          onChange={onChange("code_postal_facturation")}
        />
        <InputTexte
          label="Ville"
          onChange={onChange("code_ville_facturation")}
        />
      </div>
    </div>
  );
}
