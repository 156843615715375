import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Style from "./MessageErreur.module.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function MessageErreur({ message }) {
  return (
    <div className={Style.MessageErreur}>
      <Alert severity="error">{message}</Alert>
    </div>
  );
}
