import React from "react";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/core/styles";

//NES Style de de la div contenant le composant
const StyleDivContainer = {
  marginTop: "20px",
};

//NES Style de composant NORMAL
const CssTextField = withStyles({
  root: {
    width: "100%",
    "& .MuiInputBase-input": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
      textTransform: (props) =>
        props.texttransform ? props.texttransform : "none",
    },
    "& label": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#00000087"),
    },
    "& label.Mui-focused": {
      color: (props) => (props.couleur === "erreur" ? "#F44336" : "#208AB0"),
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "DDD",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#DDD",
      },
      "&:hover fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#DDD",
      },
      "&.Mui-focused fieldset": {
        borderColor: (props) =>
          props.couleur === "erreur" ? "#F44336" : "#208AB0",
      },
    },
  },
})(TextField);

export default function InputPassword({ value, onChange }) {
  const [values, setValues] = React.useState({
    password: value,
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    //NES retour de event au parent
    if (onChange) {
      onChange(event);
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div style={StyleDivContainer}>
      <CssTextField
        value={values.password}
        onChange={handleChange("password")}
        type={values.showPassword ? "text" : "password"}
        label="Mot de passe"
        variant="outlined"
        id="motdepasse"
        size="small"
        //couleur={isErreur ? "erreur" : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {values.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
