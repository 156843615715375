import React from "react";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const ColorButtonWhite = withStyles((theme) => ({
  root: {
    color: "white",
    fontWeight: "700",
    textTransform: "none",
    fontFamily: "Lato",
    borderColor: "white",

    "&:hover": {
      backgroundColor: "#FFFFFF20",
    },
  },
}))(Button);

const ColorButton = withStyles((theme) => ({
  root: {
    color: "#0FA600",
    fontWeight: "700",
    textTransform: "none",
    fontFamily: "Lato",
    borderColor: "#0FA600",
    height: "50px",

    "&:hover": {
      backgroundColor: "#0FA60020",
    },
  },
}))(Button);

export default function BoutonActionSecondaire({ texte, couleur, onClick }) {
  if (couleur === "Blanc") {
    return (
      <ColorButtonWhite variant="outlined" size="large" onClick={onClick}>
        {texte}
      </ColorButtonWhite>
    );
  } else
    return (
      <ColorButton variant="outlined" size="large" onClick={onClick}>
        {texte}
      </ColorButton>
    );
}
