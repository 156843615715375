//API GET https://utilisateurs.nicoandjez.net/hello
export async function GET_ApiUtilisateurs_Hello(callback) {
  const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/hello/`;
  //NES pour détecter les appels en boucle
  console.log(MonUrl);
  //NES je récupère les data depuis l'API
  const data = await fetch(MonUrl);
  //NES je convertis ce que je récupère en JSON
  const dataJson = await data.json();
  //NES je retourne à ma fonction appelante le résultat
  callback(dataJson);
}

//API POST https://utilisateurs.nicoandjez.net/inscription
export async function POST_ApiUtilisateurs_inscription(
  {
    nom,
    prenom,
    specialite,
    numero_rpps,
    numero_am,
    email,
    password,
    cgv,
    facturation_mode,
  },
  callback
) {
  //NES Construction de mon objet d'appel en JSON
  const data = {
    utilisateur: {
      nom: nom,
      prenom: prenom,
      specialite: specialite,
      numero_rpps: numero_rpps,
      numero_am: numero_am,
      email: email,
      password: password,
    },
    formule: {
      facturation_mode: facturation_mode,
    },
    conditions_generales: {
      acceptation: cgv.checked,
      version: cgv.version,
    },
  };

  //NES ma route d'appel
  const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/inscription/`;
  //NES pour détecter les appels en boucle
  console.log(MonUrl);

  //NES je récupère les data depuis l'API
  const reponseApi = await fetch(MonUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  //NES je récupère le retour
  console.log({ reponseApi });
  const reponseJson = await reponseApi.json();
  console.log(reponseJson);
  //NES Callback avec la réponse.
  callback(reponseJson);
}

//API POST https://utilisateurs.jezandnico.net/verification
export async function GET_ApiUtilistateurs_verification(
  { hash, code },
  callback
) {
  //NES Construction de mon objet d'appel en JSON
  const data = {
    verification: {
      hash: hash,
      code: code,
    },
  };

  //NES ma route d'appel
  const MonUrl = `${process.env.REACT_APP_URL_API_UTILISATEURS}/verification/`;

  //NES je j'appelle l'API et je récupère les datas
  const reponseApi = await fetch(MonUrl, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  //NES je récupère le retour
  console.log({ reponseApi });
  const reponseJson = await reponseApi.json();
  console.log(reponseJson);
  //NES Callback avec la réponse.
  callback(reponseJson);
}
