import React from "react";
import Style from "./PhotoGauche.module.css";
import Logo from "../../../components/Logo";
import { Link } from "react-router-dom";

export default function PhotoGauche({ Photo }) {
  return (
    <div className={Style.BandeauPhoto}>
      <div className={Style.Logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <img src={Photo} alt="" className={Style.Photo} />
    </div>
  );
}
