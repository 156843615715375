import React from "react";
import Style from "./AProposDeVous.module.css";
import InputTexte from "../../../components/Formulaires/InputTexte";
import InputNombres from "../../../components/Formulaires/InputNombres";
import InputEmail from "../../../components/Formulaires/InputEmail";
import InputPassword from "../../../components/Formulaires/InputPassword";
import InputConditionsGenerales from "../../../components/Formulaires/InputConditionsGenerales";

export default function AproposDeVous({ values, onChange, onCgvChange }) {
  return (
    <div className={Style.AproposDeVous}>
      <div className={Style.TitreSeparateur}>
        <div className="Titre2">A propos de vous</div>
      </div>
      <InputTexte
        value={values.nom}
        label="Nom (Obligatoire)"
        textTransform="uppercase"
        onChange={onChange("nom")}
      />
      <InputTexte
        value={values.prenom}
        label="Prénom (Obligatoire)"
        textTransform="capitalize"
        onChange={onChange("prenom")}
      />
      <InputTexte
        value={values.specialite}
        label="Spécialité"
        onChange={onChange("specialite")}
      />
      <InputNombres
        value={values.numero_rpps}
        label="N° RPPS"
        nbChiffresError="11"
        onChange={onChange("numero_rpps")}
      />
      <InputNombres
        value={values.numero_am}
        label="N° AM"
        nbChiffresError="7"
        onChange={onChange("numero_am")}
      />
      <div className={Style.TitreSeparateur}>
        <div className="Titre2">Pour vous connecter</div>
      </div>
      <InputEmail
        value={values.email}
        textTransform="none"
        label="email de connexion"
        onChange={onChange("email")}
      />
      <InputPassword
        value={values.password}
        label="Mot de passe"
        onChange={onChange("password")}
      />
      <InputConditionsGenerales
        checked={values.cgv.checked}
        onChange={onCgvChange}
      />
    </div>
  );
}
