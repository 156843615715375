import React from "react";
import Style from "./PageVerificationCompte.module.css";
import VerificationCompte from "../Inscription/Components/VerificationCompte";
import Photo from "../../Images/PhotoNicolas.png";
import queryString from "query-string";
import PhotoGauche from "./Components/PhotoGauche";
import EnteteInscription from "./Components/EnteteInscription";

export default function PageVerificationCompte() {
  //NES récupération des paramètres de l'URL
  let url = window.location.search;
  let params = queryString.parse(url);

  return (
    <div className={Style.PageVerificationCompte}>
      <PhotoGauche Photo={Photo} />
      <div className={Style.PartieDroite}>
        <EnteteInscription />
        <div className={Style.Affichage}>
          <div className="Titre1">Vérification du compte</div>
          <div className="SousTitre1">
            La vérification du compte est un élément important concernant la
            sécurité de vos données
          </div>
          <div className={Style.BlocVerification}>
            <VerificationCompte hash={params.hash} />
          </div>
        </div>
      </div>
    </div>
  );
}
