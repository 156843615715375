import React from "react";
import Style from "./Description.module.css";
import IllustrationInscription from "../../../Images/IllustrationInscription.png";
import Logo from "../../../components/Logo";
import ImageCocheBleue from "../../../Images/CocheBleue.svg";

export default function Description() {
  return (
    <div className={Style.Description}>
      <img
        className={Style.IllustrationInscription}
        src={IllustrationInscription}
        alt="IllustrationInscription"
      />
      <div className={Style.Logo}>
        <Logo couleur="bleu" />
      </div>
      <div className={Style.TagPhrase}>Tag phrase, pour dire ce que c'est</div>
      <div className="SousTitre1">
        Machin truc est construit pour être le plus simple et efficace possible.
      </div>
      <div className={Style.ListeDescription}>
        <img src={ImageCocheBleue} alt="" className={Style.ImageCocheBleue} />
        Prise de rendez-vous
      </div>
      <div className={Style.ListeDescription}>
        <img src={ImageCocheBleue} alt="" className={Style.ImageCocheBleue} />
        Suivez vos patient{" "}
      </div>
      <div className={Style.ListeDescription}>
        <img src={ImageCocheBleue} alt="" className={Style.ImageCocheBleue} />
        Saisie d’ordonnance, courriers{" "}
      </div>
      <div className={Style.ListeDescription}>
        <img src={ImageCocheBleue} alt="" className={Style.ImageCocheBleue} />
        Disponible en mobile{" "}
      </div>
      <div className={Style.ListeDescription}>
        <img src={ImageCocheBleue} alt="" className={Style.ImageCocheBleue} />
        Créez vos utilisateurs{" "}
      </div>
      <div className={Style.ListeDescription}>
        <img src={ImageCocheBleue} alt="" className={Style.ImageCocheBleue} />
        Accès au support{" "}
      </div>
    </div>
  );
}

export function DescriptionFacturation() {
  return (
    <div className={Style.Description}>
      <Logo couleur="bleu" />
      <div className={Style.TagPhrase}>La sécurité de vos données</div>
      <div className="SousTitre1">
        On ne plaisante pas avec la sécurité de vos données (comme pour les
        données médicales de vos patients) sont isolées, protégées et cryptées.
      </div>
      <div className={Style.TagPhrase}>Si je veux arrêter? </div>
      <div className="SousTitre1">
        Vous pouvez interrompre votre abonnement à tout moment il n'y a pas
        d'engagement (le mois en cours est dû). Vous pouvez télécharger vos
        données à partir de votre espace client.
      </div>
    </div>
  );
}
