import React from "react";
import Style from "./PageInscriptionTermine.module.css";
import Photo from "../../Images/PhotoJeremyNicolas.png";
import BoutonActionPricipale from "../../components/Boutons/BoutonActionPrincipale";
import { Link } from "react-router-dom";
import FireWorks from "../Inscription/Components/Fireworks";
import queryString from "query-string";
import PhotoGauche from "./Components/PhotoGauche";
import EnteteInscription from "./Components/EnteteInscription";
import goToApp from "../..//functions/redirect";

export default function PageInscriptionTermine({ match }) {
  //NES récupération des paramètres de l'URL
  let url = window.location.search;
  let params = queryString.parse(url);
  //NES construction de l'url de vérification
  let UrlVerification = `/inscription/verification?hash=${params.hash}`;

  return (
    <div className={Style.PageInscriptionTermine}>
      <PhotoGauche Photo={Photo} />

      <div className={Style.PartieDroite}>
        <EnteteInscription />

        <FireWorks />
        <div className={Style.Affichage}>
          <div className="Titre1">Inscription Terminée !</div>
          <div className="SousTitre1">
            Merci, vous pouvez dès maintenant vous connecter à #SuperLogiciel.
          </div>
          <div className={Style.BoutonPrincipal}>
            <BoutonActionPricipale
              texte="Accéder à Be"
              fullWidth
              onClick={goToApp}
            />
          </div>

          {
            //NES si j'ai passé un hash de controle c'est que le compte n'est pas activé,
            //NES on affiche alors un bloc indiquant à l'utilisateur qu'il devra activer son compte
            params.hash ? (
              <div className={Style.BlocActivation}>
                <div className={Style.TitreActivation}>
                  Activation de votre compte
                </div>
                <div className={Style.TexteActivation}>
                  Un email vous été envoyé à :
                  <div className={Style.email}>{params.email}</div> Vous devrez
                  <Link to={UrlVerification} className="BoutonLien">
                    activer votre compte
                  </Link>
                  pour que l'on puisse valider votre adresse email.
                </div>
              </div>
            ) : (
              ""
            )
          }
        </div>
      </div>
    </div>
  );
}
