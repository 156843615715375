import React from "react";
import Style from "./EnteteInscription.module.css";
import Logo from "../../../components/Logo";
import BoutonSecondaire from "../../../components/Boutons/BoutonActionSecondaire";
import { Link } from "react-router-dom";
import goToApp from "../../../functions/redirect";

export default function EnteteInscription() {
  return (
    <div className={Style.EnteteInscription}>
      <div className={Style.Logo}>
        <Link to="/">
          <Logo couleur="bleu" />
        </Link>
      </div>
      <div className={Style.BlocConnexion}>
        <div className={Style.LabelConnexion}>Vous avez déjà un compte ?</div>
        <BoutonSecondaire texte="Connexion" onClick={goToApp} />
      </div>
    </div>
  );
}
