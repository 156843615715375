import React from "react";
import ImageLogo from "../Images/Be.svg";
import ImageLogoBleu from "../Images/BeBleu.svg";

export default function Logo({ couleur }) {
  return (
    <div>
      <img src={couleur === "bleu" ? ImageLogoBleu : ImageLogo} alt="Logo" />
    </div>
  );
}
