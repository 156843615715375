import React, { useState } from "react";
import Style from "./Page30jEssai.module.css";
import PhotoJeremy from "../../Images/PhotoJeremy.png";
import BoutonActionPrincipale from "../../components/Boutons/BoutonActionPrincipale";
import InputTexte from "../../components/Formulaires/InputTexte";
//import InputNombres from "../../components/Formulaires/InputNombres";
import InputEmail from "../../components/Formulaires/InputEmail";
import InputPassword from "../../components/Formulaires/InputPassword";
import InputConditionsGenerales from "../../components/Formulaires/InputConditionsGenerales";
import Descrition from "./Components/Description";
import EnteteInscription from "./Components/EnteteInscription";
import "../../Data/ApiUtilisateur";
import { POST_ApiUtilisateurs_inscription } from "../../Data/ApiUtilisateur";
import MessageErreur from "../../components/Messages/MessageErreur";
import PhotoGauche from "./Components/PhotoGauche";

export default function Page30jEssai() {
  //NES Gestion du State de mon formulaire
  const [values, setValues] = useState({
    nom: "",
    prenom: "",
    specialite: "",
    numero_rpps: "",
    numero_am: "",
    email: "",
    password: "",
    cgv: "",
    facturation_mode: "30j_essai",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  //NES Messages d'erreur
  const [erreur, setErreur] = useState();

  //NES cas particulier des CGV
  const handleCgv = (cgv) => {
    setValues({ ...values, cgv: cgv });
  };

  //NES Inscription
  const Inscription30j = () => {
    POST_ApiUtilisateurs_inscription(values, ReponseApi);
  };

  //NES redirection si c'est bon
  const ReponseApi = (data) => {
    //NES cas d'erreur génériques
    if (data.code === "erreur") {
      setErreur(data.erreur.message);
      //NES Gestion du cas d'erreur du mail existant
      if (data.erreur.code === "email_deja_existant") {
        setErreur("Cette adresse email est déjà utilisée");
      }
    }

    //NES Pas de cas d'erreur -> redirection
    if (data.code === "OK") {
      let monUrl =
        "termine?email=" +
        data.verification_compte.email +
        "&hash=" +
        data.verification_compte.hash;
      setErreur();
      window.location.href = monUrl;
    }
  };

  return (
    <div className={Style.Page30jEssai}>
      <PhotoGauche Photo={PhotoJeremy} />
      <div className={Style.PartieDroite}>
        <EnteteInscription />
        <div className={Style.Wrapper}>
          <div className={Style.Formulaire}>
            <div className="Titre1">Inscription</div>
            <div className="SousTitre1">
              30 jours gratuits, pas de carte de crédit
            </div>

            <div className={Style.TitreSeparateur}>
              <div className="Titre2">A propos de vous</div>
            </div>
            <InputTexte
              label="Nom (Obligatoire)"
              textTransform="uppercase"
              onChange={handleChange("nom")}
            />
            <InputTexte
              label="Prénom (Obligatoire)"
              textTransform="capitalize"
              onChange={handleChange("prenom")}
            />
            {/* //NES c'est trop lourd pour juste essayer un logiciel pour l'isntant je coupe
            <InputTexte
              label="Spécialité"
              onChange={handleChange("specialite")}
            />
            <InputNombres
              label="N° RPPS"
              nbChiffresError="11"
              onChange={handleChange("numero_rpps")}
            />
            <InputNombres
              label="N° AM"
              nbChiffresError="7"
              onChange={handleChange("numero_am")}
            />
            */}
            <div className={Style.TitreSeparateur}>
              <div className="Titre2">Pour vous connecter</div>
            </div>
            <InputEmail
              textTransform="none"
              label="email de connexion"
              onChange={handleChange("email")}
            />
            <InputPassword
              label="Mot de passe"
              onChange={handleChange("password")}
            />
            <InputConditionsGenerales onChange={handleCgv} />
            <div className={Style.BoutonPrincipal}>
              <BoutonActionPrincipale
                fullWidth={true}
                texte="Essayer pendant 30j"
                onClick={Inscription30j}
                disabled={
                  values.nom === "" ||
                  values.prenom === "" ||
                  values.login === "" ||
                  values.password === "" ||
                  !values.cgv.checked
                }
              />
              {erreur ? <MessageErreur message={erreur} /> : ""}
            </div>
          </div>
          <div className={Style.Description}>
            <Descrition />
          </div>
        </div>
      </div>
    </div>
  );
}
